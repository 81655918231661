import React, { useState, useEffect, useCallback } from 'react';
import ladderImage from './ladder.png'; // Make sure this file exists in your src folder

const GAME_WIDTH = 800;
const GAME_HEIGHT = 600;
const PLAYER_SIZE = 30;
const BARREL_SIZE = 20;
const PLATFORM_HEIGHT = 20;
const LADDER_WIDTH = 30;
const GRAVITY = 2;
const HOLE_WIDTH = 60;
const WALL_WIDTH = 20;
const JUMP_HEIGHT = PLAYER_SIZE * 2;
const JUMP_DURATION = 500; // 0.5 seconds for up
const FALL_SPEED = 3; // Slower falling speed

const platforms = [
  { y: GAME_HEIGHT - 100, width: GAME_WIDTH - HOLE_WIDTH, x: HOLE_WIDTH },
  { y: GAME_HEIGHT - 250, width: GAME_WIDTH * 0.8, x: 0 },
  { y: GAME_HEIGHT - 400, width: GAME_WIDTH * 0.8, x: GAME_WIDTH * 0.2 },
];

const ladders = [
  { x: GAME_WIDTH * 0.7, yBottom: GAME_HEIGHT - 100, yTop: GAME_HEIGHT - 250 },
  { x: GAME_WIDTH * 0.2, yBottom: GAME_HEIGHT - 250, yTop: GAME_HEIGHT - 400 },
];

const wall = { x: 0, y: GAME_HEIGHT - 400, width: WALL_WIDTH, height: 150 };

const DonkeyKong = () => {
  const [playerPosition, setPlayerPosition] = useState({ x: HOLE_WIDTH, y: GAME_HEIGHT - 130 });
  const [barrels, setBarrels] = useState([]);
  const [isClimbing, setIsClimbing] = useState(false);
  const [isJumping, setIsJumping] = useState(false);
  const [jumpStartY, setJumpStartY] = useState(0);
  const [jumpPhase, setJumpPhase] = useState('');
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameTime, setGameTime] = useState(0);
  const [maxBarrels, setMaxBarrels] = useState(20);

  const isOnPlatform = useCallback((x, y) => {
    return platforms.some(platform => 
      y + PLAYER_SIZE >= platform.y &&
      y + PLAYER_SIZE <= platform.y + PLATFORM_HEIGHT &&
      x >= platform.x &&
      x + PLAYER_SIZE <= platform.x + platform.width
    );
  }, []);

  const isOnLadder = useCallback((x, y) => {
    return ladders.some(ladder => 
      x + PLAYER_SIZE / 2 >= ladder.x &&
      x + PLAYER_SIZE / 2 <= ladder.x + LADDER_WIDTH &&
      y + PLAYER_SIZE >= ladder.yTop &&
      y <= ladder.yBottom
    );
  }, []);

  const canMoveToPosition = useCallback((x, y) => {
    return isOnPlatform(x, y) || isOnLadder(x, y) || isJumping;
  }, [isOnPlatform, isOnLadder, isJumping]);

  const movePlayer = useCallback((direction) => {
    setPlayerPosition(prev => {
      let newX = prev.x + (direction === 'left' ? -10 : 10);
      newX = Math.max(0, Math.min(GAME_WIDTH - PLAYER_SIZE, newX));
      
      if (canMoveToPosition(newX, prev.y)) {
        return { ...prev, x: newX };
      }
      return prev;
    });
  }, [canMoveToPosition]);

  const climb = useCallback((direction) => {
    if (isJumping) return;
    setPlayerPosition(prev => {
      let newY = prev.y + (direction === 'up' ? -10 : 10);
      
      if (isOnLadder(prev.x, newY)) {
        setIsClimbing(true);
        return { ...prev, y: newY };
      }

      if (isOnPlatform(prev.x, newY)) {
        setIsClimbing(false);
        return { ...prev, y: newY };
      }

      setIsClimbing(false);
      return prev;
    });
  }, [isOnLadder, isOnPlatform, isJumping]);

  const jump = useCallback(() => {
    if (isJumping || isClimbing) return;
    setIsJumping(true);
    setJumpPhase('up');
    setJumpStartY(playerPosition.y);
    setTimeout(() => {
      setJumpPhase('down');
    }, JUMP_DURATION);
  }, [isJumping, isClimbing, playerPosition.y]);

  const updateBarrelPosition = (barrel) => {
    let newY = barrel.y + GRAVITY;
    let newX = barrel.x + barrel.speed;

    for (let platform of platforms) {
      if (newY + BARREL_SIZE > platform.y && newY + BARREL_SIZE <= platform.y + PLATFORM_HEIGHT &&
          barrel.y + BARREL_SIZE <= platform.y && 
          newX + BARREL_SIZE > platform.x && newX < platform.x + platform.width) {
        newY = platform.y - BARREL_SIZE;
        break;
      }
    }

    if (newY >= wall.y && newY <= wall.y + wall.height && newX <= wall.width) {
      barrel.speed = Math.abs(barrel.speed);
      newX = wall.width;
    }

    if (newX + BARREL_SIZE >= GAME_WIDTH) {
      barrel.speed = -Math.abs(barrel.speed);
    }

    if (newY > GAME_HEIGHT - 100 && newX < HOLE_WIDTH) {
      return null;
    }

    return { ...barrel, x: newX, y: newY };
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          movePlayer('left');
          break;
        case 'ArrowRight':
          movePlayer('right');
          break;
        case 'ArrowUp':
          climb('up');
          break;
        case 'ArrowDown':
          climb('down');
          break;
        case ' ':
          jump();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [movePlayer, climb, jump]);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      if (gameOver) return;

      setGameTime(prevTime => prevTime + 1);

      setBarrels((prevBarrels) => {
        const newBarrels = prevBarrels
          .map(updateBarrelPosition)
          .filter(barrel => barrel !== null && barrel.y < GAME_HEIGHT);

        if (Math.random() < 0.04 && newBarrels.length < maxBarrels) {
          newBarrels.push({
            x: GAME_WIDTH - BARREL_SIZE,
            y: 0,
            speed: -4,
          });
        }

        return newBarrels;
      });

      setScore((prevScore) => prevScore + 1);

      setPlayerPosition(prev => {
        if (isJumping) {
          if (jumpPhase === 'up') {
            const newY = Math.max(jumpStartY - JUMP_HEIGHT, prev.y - 10);
            if (newY === jumpStartY - JUMP_HEIGHT) {
              setJumpPhase('down');
            }
            return { ...prev, y: newY };
          } else if (jumpPhase === 'down') {
            let newY = prev.y + FALL_SPEED;
            if (isOnPlatform(prev.x, newY)) {
              setIsJumping(false);
              setJumpPhase('');
              newY = Math.floor(newY / PLATFORM_HEIGHT) * PLATFORM_HEIGHT - PLAYER_SIZE;
            }
            return { ...prev, y: newY };
          }
        } else if (!isOnPlatform(prev.x, prev.y) && !isOnLadder(prev.x, prev.y)) {
          return { ...prev, y: Math.min(prev.y + FALL_SPEED, GAME_HEIGHT - PLAYER_SIZE) };
        }
        return prev;
      });
    }, 50);

    return () => clearInterval(gameLoop);
  }, [gameOver, isOnPlatform, isOnLadder, maxBarrels, isJumping, jumpPhase, jumpStartY]);

  useEffect(() => {
    if (gameTime % 1200 === 0 && gameTime > 0) {
      const minutes = gameTime / 1200;
      if (minutes > 2) {
        setMaxBarrels(prevMax => prevMax + 5);
      }
    }
  }, [gameTime]);

  useEffect(() => {
    const checkCollisions = () => {
      const playerRect = {
        left: playerPosition.x,
        right: playerPosition.x + PLAYER_SIZE,
        top: playerPosition.y,
        bottom: playerPosition.y + PLAYER_SIZE,
      };

      barrels.forEach((barrel) => {
        const barrelRect = {
          left: barrel.x,
          right: barrel.x + BARREL_SIZE,
          top: barrel.y,
          bottom: barrel.y + BARREL_SIZE,
        };

        if (
          playerRect.left < barrelRect.right &&
          playerRect.right > barrelRect.left &&
          playerRect.top < barrelRect.bottom &&
          playerRect.bottom > barrelRect.top
        ) {
          setGameOver(true);
        }
      });
    };

    checkCollisions();
  }, [playerPosition, barrels]);

  return (
    <div
      style={{
        width: GAME_WIDTH,
        height: GAME_HEIGHT,
        border: '1px solid black',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {platforms.map((platform, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: platform.x,
            top: platform.y,
            width: platform.width,
            height: PLATFORM_HEIGHT,
            backgroundColor: 'brown',
          }}
        />
      ))}
      {ladders.map((ladder, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: ladder.x,
            top: ladder.yTop,
            width: LADDER_WIDTH,
            height: ladder.yBottom - ladder.yTop,
            backgroundImage: `url(${ladderImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat-y',
          }}
        />
      ))}
      <div
        style={{
          position: 'absolute',
          left: wall.x,
          top: wall.y,
          width: wall.width,
          height: wall.height,
          backgroundColor: 'red',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: playerPosition.x,
          top: playerPosition.y,
          width: PLAYER_SIZE,
          height: PLAYER_SIZE,
          backgroundColor: isClimbing ? 'green' : (isJumping ? 'yellow' : 'blue'),
        }}
      />
      {barrels.map((barrel, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: barrel.x,
            top: barrel.y,
            width: BARREL_SIZE,
            height: BARREL_SIZE,
            backgroundColor: 'brown',
            borderRadius: '50%',
          }}
        />
      ))}
      <div style={{ position: 'absolute', top: 10, left: 10, fontSize: 20 }}>
        Score: {score} | Time: {Math.floor(gameTime / 20)}s | Barrels: {barrels.length}/{maxBarrels}
      </div>
      {gameOver && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 40,
            fontWeight: 'bold',
          }}
        >
          Game Over!
        </div>
      )}
    </div>
  );
};

export default DonkeyKong;