import React from 'react';
import DonkeyKong from './DonkeyKong';

function App() {
  return (
    <div className="App">
      <h1>Retro Donkey Kong</h1>
      <DonkeyKong />
    </div>
  );
}

export default App;